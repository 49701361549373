import { Suspense, useEffect, useState, lazy } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const App = lazy(() => import('./App'));

const AuthenticatedApp: React.FC = () => {
  const [token, setToken] = useState<string>();
  const { loginWithRedirect, isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    if (user) {
      const getToken = async () => {
        const token = await getAccessTokenSilently();

        if (token) {
          setToken(token);
        }
      };
      getToken();
    }
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    if (isAuthenticated || isLoading) {
      return;
    }

    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return token && user ? (
    <Suspense>
      <App token={token} user={user} />
    </Suspense>
  ) : null;
};

export default AuthenticatedApp;
